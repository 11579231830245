.user
{
    border: 2px solid black;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 75vw;
    padding: 1rem;
    margin: auto;
    margin-top: 1rem;
    border-radius: 18px;

}

.user__image
{
    width: 7rem;
    border-radius: 50%;
}
span{
    font-weight: 400;
}
.user__info{
    text-align: center;
    border: 2px solid black;
    width:80vw;
    background-color: rgb(171, 227, 255);
    border-radius: 22px;
    margin-top: 1rem;
    
}
@media screen and (min-width: 720px) {
  .user
    {
        margin: auto;
        margin-top: 1rem;
        width: 40vw;
        max-width: 400px;
    }

    .user__info
    { 
        width:inherit; 
        max-width: 400px;  
    }
}

