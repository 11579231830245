.nav-bar
{
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 0;
    left: 0 ;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: rgb(11, 11, 11);
    box-shadow: 0px 5px 5px 0px #888888;
}

.nav-bar__logo
{
    font-family: 'Oswald', sans-serif;
    margin: 1rem;
}

.nav-bar__btn
{
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 120px;
    height: 2.1rem;
    border-radius: 12px;
    color: white;
    background-color: rgb(0, 0, 0);
    border: 2px solid rgb(0, 0, 0);
    font-weight: 500;
    font-size: 0.9rem;
}

.nav-bar__btn:hover
{
    background-color: rgb(171, 227, 255);
    color: black;
}

.nav-bar__btn:active
{
  transform: translateY(4px);
}